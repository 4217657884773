/* eslint-disable */
/** 
 * This file contains language specific message functions for tree-shaking. 
 * 
 *! WARNING: Only import messages from this file if you want to manually
 *! optimize your bundle. Else, import from the `messages.js` file. 
 * 
 * Your bundler will (in the future) automatically replace the index function 
 * with a language specific message function in the build step. 
 */


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const website_title = () => `Subdl: دانلود زیرنویس رایگان برای فیلم‌ها و سریال‌های تلویزیونی | بیش از 100 زبان`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const website_description = () => `دانلود زیرنویس رایگان فیلم و سریال‌های تلویزیونی به چندین زبان مختلف در Subdl.com. زیرنویس تمام فصول سریال‌ها را تمام و کمال، به‌طور فوری از منابع مختلف دریافت کنید`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const website_keywords = () => `دانلود زیرنویس، زیرنویس دانلود، زیرنویس رایگان، فیلم‌های رایگان، سریال‌های تلویزیونی، زیرنویس‌های زبان‌های مختلف، جستجوی زیرنویس`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const index_title = () => `زیرنویس‌های بی نقص را به هر زبانی برای تمام فیلم و سریال های مورد علاقه‌تان پیدا کنید!`


/**
 * @param {{ query: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_page_title = (params) => `جستجوی زیرنویس ${params.query} - SUBDL`


/**
 * @param {{ query: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_page_description = (params) => `جستجوی فوری زیرنویس ${params.query} به زبان‌های انگلیسی، عربی، اندونزیایی، فرانسوی، آلمانی، اسپانیایی و بیشتر.`


/**
 * @param {{ query: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_page_keywords = (params) => `دانلود زیرنویس ${params.query}، زیرنویس ${params.query}، دانلود زیرنویس ${params.query}، زیرنویس BluRay ${params.query}، زیرنویس HDTV ${params.query}، دانلود رایگان زیرنویس ${params.query}، زیرنویس بسته ${params.query}، زیرنویس رسمی ${params.query}، فایل زیرنویس SRT ${params.query}، هماهنگ‌سازی زیرنویس ${params.query}، ترجمه زیرنویس ${params.query}، زیرنویس با کیفیت بالا ${params.query}، دانلود زیرنویس ${params.query} برای موبایل، زیرنویس ${params.query} برای ناشنوایان، آخرین به‌روزرسانی زیرنویس ${params.query}`


/**
 * @param {{ movieName: NonNullable<unknown>, movieYear: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const film_page_title = (params) => `زیرنویس ${params.movieName} (${params.movieYear}) - SUBDL`


/**
 * @param {{ movieName: NonNullable<unknown>, movieYear: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const film_page_description = (params) => `دانلود فوری زیرنویس ${params.movieName} (${params.movieYear}) به زبان‌های انگلیسی، عربی، اندونزیایی، فرانسوی، آلمانی، اسپانیایی و بیشتر.`


/**
 * @param {{ movieName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const film_page_keywords = (params) => `دانلود زیرنویس ${params.movieName}، زیرنویس ${params.movieName}، دانلود زیرنویس ${params.movieName}، زیرنویس BluRay ${params.movieName}، زیرنویس HDTV ${params.movieName}، دانلود رایگان زیرنویس ${params.movieName}، زیرنویس بسته ${params.movieName}، زیرنویس رسمی ${params.movieName}، فایل زیرنویس SRT ${params.movieName}، همگام‌سازی زیرنویس ${params.movieName}، ترجمه زیرنویس ${params.movieName}، زیرنویس با کیفیت بالا ${params.movieName}، دانلود زیرنویس ${params.movieName} برای موبایل، زیرنویس ${params.movieName} برای ناشنوایان، آخرین به‌روزرسانی زیرنویس ${params.movieName}`


/**
 * @param {{ movieName: NonNullable<unknown>, movieYear: NonNullable<unknown>, lang: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const film_page_title_with_lang = (params) => `زیرنویس ${params.lang} ${params.movieName} (${params.movieYear}) - SUBDL`


/**
 * @param {{ movieName: NonNullable<unknown>, movieYear: NonNullable<unknown>, lang: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const film_page_description_with_lang = (params) => `دانلود فوری زیرنویس ${params.movieName} (${params.movieYear}) به زبان ${params.lang}.`


/**
 * @param {{ movieName: NonNullable<unknown>, lang: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const film_page_keywords_with_lang = (params) => `دانلود زیرنویس ${params.movieName}، زیرنویس ${params.lang} ${params.movieName}، دانلود زیرنویس ${params.lang} ${params.movieName}، زیرنویس BluRay ${params.movieName}، زیرنویس HDTV ${params.movieName}، دانلود رایگان زیرنویس ${params.movieName}، زیرنویس بسته ${params.lang} ${params.movieName}، زیرنویس رسمی ${params.movieName}، فایل زیرنویس SRT ${params.movieName}، همگام‌سازی زیرنویس ${params.lang} ${params.movieName}، ترجمه زیرنویس ${params.movieName}، زیرنویس با کیفیت بالا ${params.movieName}، دانلود زیرنویس ${params.movieName} برای موبایل، زیرنویس ${params.movieName} برای ناشنوایان، آخرین به‌روزرسانی زیرنویس ${params.movieName}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_home = () => `خانه`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_latest = () => `آخرین زیرنویس‌ها`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_trends = () => `ترند هفته`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_plugins = () => `افزونه‌ها`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_blog = () => `بلاگ`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_community = () => `گروه تلگرام`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_search = () => `جستجو`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_account = () => `حساب`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_logout = () => `خروج`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_join = () => `عضو شوید`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_menu = () => `فهرست`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_placeholder = () => `جستجوی فیلم و سریال‌ها`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_no_results = () => `هیچ نتیجه‌ای یافت نشد`


/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_results = (params) => `مطابقت‌ها (${params.count} نتیجه)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_settings = () => `تنظیمات جستجو`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_setting_movie = () => `فیلم‌ها فقط`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_setting_tv = () => `سریال‌ها فقط`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_setting_selected = () => `فیلتر زبان`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_setting_has_selected_language = () => `زبان‌های انتخاب شده`


/**
 * @param {{ query: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_other_results = (params) => `نمایش همه نتایج برای "${params.query}"`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_page_list_title = () => `نتایج جستجو`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_page_list_no_results = () => `هیچ نتیجه‌ای یافت نشد`


/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_page_list_results = (params) => `مطابقت‌ها (${params.count} نتیجه)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const footer_menu_ads = () => `تبلیغات`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const footer_menu_api_doc = () => `مستندات وبسرویس`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const setting_menu_title = () => `تنظیمات سایت`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const setting_menu_dark_theme = () => `تم تاریک`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const setting_menu_light_theme = () => `تم روشن`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const setting_menu_site_language = () => `زبان سایت`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const setting_menu_filter_languages = () => `فیلتر زبان‌ها`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_title = () => `عنوان`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_quality = () => `کیفیت`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_author = () => `مولف`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_releases = () => `اسم فایل‌ها`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_rate = () => `امتیاز`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_date = () => `تاریخ`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_comment = () => `نظر`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_n_id = () => `n_id`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_slug = () => `اسلاگ`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_link = () => `لینک`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loading = () => `در حال بارگذاری...`


/**
 * @param {{ lang: NonNullable<unknown>, movieName: NonNullable<unknown>, movieYear: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_title = (params) => `دانلود زیرنویس ${params.lang} برای ${params.movieName} (${params.movieYear})`


/**
 * @param {{ lang: NonNullable<unknown>, movieName: NonNullable<unknown>, movieYear: NonNullable<unknown>, seasonName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_title_season = (params) => `دانلود زیرنویس‌های ${params.lang} برای ${params.movieName} (${params.movieYear}) - ${params.seasonName}`


/**
 * @param {{ movieName: NonNullable<unknown>, movieYear: NonNullable<unknown>, seasonNumber: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_title_season_number = (params) => `زیرنویس‌های ${params.movieName} (${params.movieYear}) - ${params.seasonNumber}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_release_info = () => `هماهنگ با نسخه‌های زیر`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_commentary_by = () => `ناشر`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_tags = () => `برچسب‌ها`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_comments = () => `نظرات`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_download = () => `دانلود`


/**
 * @param {{ language: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_sup_title = (params) => `دانلود زیرنویس ${params.language}`


/**
 * @param {{ language: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_main_download_button = (params) => `دانلود زیرنویس ${params.language}`


/**
 * @param {{ language: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_mobile_button = (params) => `دانلود زیرنویس ${params.language} برای تلفن همراه`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_seasons = () => `فصل‌ها`


/**
 * @param {{ seasonNumber: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_season = (params) => `فصل ${params.seasonNumber}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_rate_subtitle = () => `امتیاز زیرنویس`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_rate_and_comment = () => `امتیاز و نظرات`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_comment = () => `نظر`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_send = () => `ارسال`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_close = () => `بستن`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_published_on = () => `تاریخ انتشار`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_rate = () => `نمره`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_downloads = () => `تعداد دانلود`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_hearing_impaired = () => `مخصوص ناشنوایان`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_submit_comment = () => `ارسال نظر`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_sup = () => `مترجم / مولف`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_rating = () => `رتبه بندی`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_downloads = () => `دانلود‌ها`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_comments = () => `نظرات`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_latest_subtitles = () => `زیرنویس‌های اخیر`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_pinned_subtitles = () => `زیرنویس‌های سنجاق شده`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_more_subtitles = () => `زیرنویس بیش‌تر`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_subtitles = () => `زیرنویس‌ها`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_download = () => `دانلود`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_hearing_impaired = () => `مخصوص ناشنوایان`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_submit_comment = () => `ارسال نظر`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_page = () => `صفحه`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_comment_sup = () => `از اول سال ۲۰۲۴میلادی`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_rating_sup = () => `بر اساس نظرات کاربران`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const next = () => `بعدی`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const previous = () => `قبلی`


/**
 * @param {{ lang: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_page_title = (params) => `آخرین زیرنویس ها ${params.lang} - SUBDL`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_page_subtitles = () => `زیرنویس‌های اخیر`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_table_movie = () => `فیلم`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_table_name = () => `عنوان`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_table_author = () => `نویسنده`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_table_downloads = () => `دانلود‌ها`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_table_time = () => `زمان`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_page_caption = () => `لیست زیرنویس‌های اخیر شما.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_seasons = () => `فصل`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav = () => `منوی کاربر`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav_dashboard = () => `داشبورد`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav_upload = () => `آپلود زیرنویس`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav_subtitles = () => `زیرنویس‌های من`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav_profile = () => `پروفایل`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav_api = () => `وبسرویس`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav_logout = () => `خروج`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_dashboard = () => `داشبورد`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_upload = () => `آپلود زیرنویس`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_upload_description = () => `آپلود زیرنویس‌های خود به صورت یک‌جا.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_subtitles = () => `زیرنویس‌های من`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_subtitles_description = () => `مدیریت زیرنویس‌های خود`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_api = () => `وبسرویس`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_api_description = () => `مدیریت وبسرویس و دریافت کلید `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_profile = () => `پروفایل`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_profile_description = () => `به روز رسانی پروفایل شما`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_title = () => `آپلود زیرنویس`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_src_type = () => `جستجوی فیلم از`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_choose_movie_tv = () => `انتخاب فیلم/سریال`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_drop_region = () => `فایل‌های زیرنویس را اینجا بکشید یا کلیک کنید تا فایل‌ها را انتخاب کنید`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_files = () => `فایل‌ها`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_releases = () => `نسخه‌ها`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_release_add = () => `افزودن`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_release_remove = () => `حذف`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_language = () => `زبان`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_select_language = () => `زبان را انتخاب کنید`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_release_name = () => `نام`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_release_type = () => `نوع انتشار (کیفیت)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_select_quality = () => `کیفیت را انتخاب کنید`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_production_type = () => `نوع تولید`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_select_production_type = () => `نوع تولید را انتخاب کنید`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_remove_movie = () => `حذف`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_prod_type_transcript = () => `ترا نوشت (ترجمه به صورت شنیداری)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_prod_type_translated = () => `ترجمه زیرنویس`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_prod_type_improved = () => `بهبود زیرنویس`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_prod_type_retail = () => `نسخه ریتیل`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_prod_type_machine = () => `ترجمه ماشینی`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_season = () => `فصل`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_episode = () => `قسمت`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_full_season = () => `فصل کامل`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_comment = () => `نظر`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_hearing_impaired = () => `مخصوص ناشنوایان`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_fps = () => `فریم بر ثانیه`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_submit = () => `آپلود`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_fps_default = () => `نمی‌دانم`


export { edit_subtitle_page_title } from "./en.js"

/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_download = () => `دانلود سریع`


/**
 * @param {{ seasonNumber: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const season = (params) => `فصل ${params.seasonNumber}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_title = () => `ثبت نام - SUBDL`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_description = () => `به SUBDL بپیوندید و از زبان‌های مورد نظر شما دانلود و آپلود زیرنویس‌ها را شروع کنید.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_form_title = () => `ثبت نام`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_form_subtitle = () => `اطلاعات خود را وارد کنید تا یک حساب ایجاد کنید`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_email = () => `ایمیل`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_username = () => `نام کاربری`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_password = () => `گذرواژه`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_country = () => `کشور`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_create_account = () => `ایجاد حساب`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_sign_up_with_google = () => `ثبت نام با گوگل`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_have_an_account = () => `حساب دارید؟`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_sign_in = () => `ورود`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_title = () => `ورود - SUBDL`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_description = () => `با استفاده از ایمیل و گذرواژه وارد شوید`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_form_title = () => `ورود`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_form_subtitle = () => `اطلاعات خود را وارد کنید تا ورود شوید`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_button = () => `ورود`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_with_google = () => `ورود با گوگل`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_have_an_account = () => `حساب خود را دارید؟`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_sign_up = () => `ثبت نام`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_forget_password = () => `گذرواژه را فراموش کرده اید؟`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const page = () => `صفحه`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const logout_title = () => `خارح شدید `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const logout_description = () => `شما با موفقیت خارج شدید.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_filter = () => `جستجو...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_filter_description = () => `وارد کردن متن، سپس Enter را فشار دهید یا آیکون را کلیک کنید`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mysubtitles_page_title = () => `زیرنویس‌های من`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mysubtitles_page_description = () => `مدیریت زیرنویس‌های من`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const add_subtitle = () => `افزودن زیرنویس`


export { edit_subtitle } from "./en.js"

/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const not_found_title = () => `صفحه یافت نشد`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const not_found_description = () => `صفحه یافت نشد`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const not_found_go_home = () => `به صفحه اصلی برو`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_title = () => `مشکلی پیش آمد!`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_description = () => `مشکلی پیش آمد!`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_go_home = () => `به صفحه اصلی برو`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const no_subtitles_title = () => `زیرنویس‌های یافت نشد`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const no_subtitles_upload_one = () => `شما می‌توانید زیرنویس‌ها را برای این صفحه بارگذاری کنید اگر یکی دارید.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const no_subtitles_go_upload = () => `آپلود زیرنویس`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const api_page_title = () => `وبسرویس`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const api_page_description = () => `مدیریت و دریافت کلید وبسرویس`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const api_doc_page_title = () => `مستندات وبسرویس`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const api_doc_page_description = () => `مستندات API جستجوی زیرنویس`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const plugin_page_title = () => `افزونه‌ها`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const plugin_page_description = () => `افزونه‌ها برای Subdl.com`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_recent_search = () => `جستجوی اخیر`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_clear_recent_search = () => `پاک کردن`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_trends = () => `روندها`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bio_min_length = () => `بیوگرافی باید حداقل 10 کاراکتر باشد.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bio_max_length = () => `بیوگرافی نباید بیشتر از 500 کاراکتر باشد.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const comment_min_length = () => `نظر باید حداقل 5 کاراکتر باشد.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const comment_max_length = () => `نظر نباید بیشتر از 500 کاراکتر باشد.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_required = () => `لطفاً ابتدا وارد شوید`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const enter_comment = () => `لطفاً نظر خود را وارد کنید`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const comment_sent = () => `نظر ارسال شد`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const comment_submitted = () => `نظر شما ثبت شد`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const comment_submitted_description = () => `نظر شما با موفقیت ثبت شد`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const comment_placeholder = () => `نظری درباره این زیرنویس بنویسید`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const submit_comment = () => `ارسال نظر`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const rating = () => `امتیاز`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const panel_subtitles_uploaded = () => `بارگذاری شده`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const panel_subtitles_published = () => `منتشر شده`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const panel_subtitles_my_subtitles = () => `زیرنویس‌های من`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const panel_subtitles_uploaded_description = () => `لیست زیرنویس‌های در انتظار تأیید.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const panel_subtitles_delete_message = () => `آیا مطمئن هستید که می‌خواهید این زیرنویس را حذف کنید؟`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const panel_subtitles_deleted = () => `زیرنویس با موفقیت حذف شد.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const uploaded_subtitles_id = () => `شناسه`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const uploaded_subtitles_name = () => `نام`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const uploaded_subtitles_language = () => `زبان`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const uploaded_subtitles_date = () => `تاریخ`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const uploaded_subtitles_status = () => `وضعیت`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_action = () => `عملیات`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_status_pending = () => `در انتظار`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_status_published = () => `منتشر شده`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_status_rejected = () => `رد شده`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_subtitle_delete = () => `حذف`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_subtitle_edit = () => `ویرایش`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_subtitle_re_send = () => `ارسال مجدد`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_send_telegram = () => `آیا مطمئن هستید که می‌خواهید پیام ربات را به تلگرام ارسال کنید؟`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_movie = () => `فیلم`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_name = () => `نام`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_lang = () => `زبان`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_hi = () => `ناشنوایان`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_date = () => `تاریخ`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_status = () => `وضعیت`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_download = () => `دانلود`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_action = () => `عملیات`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_send_to_ch = () => `ارسال به کانال`


/**
 * @param {{ name: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const update_subtitle_page_title = (params) => `ویرایش زیرنویس`


export { update_subtitle_page_submit } from "./en.js"

/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_my_public_page = () => `صفحه ام انتشار من`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_my_public_page_description = () => `مشاهده صفحه ام انتشار شما`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_setting_all = () => `همه`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_password_confirm = () => `تایید گذرواژه`
